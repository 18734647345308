import { Loader } from '@googlemaps/js-api-loader'

const storeLocator = (map) => {
    const myLocationBtn = document.getElementById('my-location-button')
    const searchStoresBtn = document.getElementById('store-locator-submit')
    const searchStoresInput = document.getElementById('store-locator-query')

    if (!myLocationBtn || !searchStoresBtn || !searchStoresInput) return

    myLocationBtn.addEventListener('click', async function () {
        await navigator.geolocation.getCurrentPosition(geolocationSuccess)
    })

    function geolocationSuccess(event) {
        if (event.coords && event.coords.latitude && event.coords.longitude) {
            searchStoresInput.value = `${event.coords.latitude}, ${event.coords.longitude}`
            searchStoresBtn.click()
        }
    }

    searchStoresBtn.addEventListener('click', function () {
        // Escape values
        const params = new URLSearchParams()
        params.append('query', searchStoresInput.value)

        axios.post(`/store-locator/location`, params)
            .then(response => {
                const output = response.data

                if (output && output['lat'] && output['lng']) {
                    const coordinates = new google.maps.LatLng(output['lat'], output['lng'])
                    map.panTo(coordinates)
                    map.setZoom(14)
                }
            })
    })

    searchStoresInput.addEventListener('keydown', function (event) {
        if (event.key.toLowerCase() === 'enter') {
            searchStoresBtn.click()
        }
    })
}

const initMap = async () => {
    const { Map } = await google.maps.importLibrary('maps')
    const { spherical } = await google.maps.importLibrary('geometry')

    let storeLocatorMarkers = []
    let loaded = false

    const storeOverview = document.getElementById('store-overview')
    const searchStoresInput = document.getElementById('store-locator-query')
    const storeLocatorMapElem = document.getElementById('store-locator-map')

    if (!storeLocatorMapElem) return

    const center = {
        lat: window.app.defaultLatitude,
        lng: window.app.defaultLongitude
    }

    const storeLocatorMap = new Map(storeLocatorMapElem, {
        zoom: window.app.defaultZoom,
        center: center
    })
    const infoPopup = new google.maps.InfoWindow({ content: '' })

    storeLocator(storeLocatorMap)

    // Events
    storeLocatorMap.addListener('zoom_changed', () => {
        updateStores(storeLocatorMap)
    })
    storeLocatorMap.addListener('dragend', () => {
        updateStores(storeLocatorMap)
    })
    storeLocatorMap.addListener('idle', () => {
        if (loaded) return
        loaded = true

        updateStores(storeLocatorMap)
    })

    function removeAllMarkers() {
        for (let i = 0; i < storeLocatorMarkers.length; i++) {
            storeLocatorMarkers[i].setMap(null)
        }
        storeLocatorMarkers = []
    }

    function updateStores() {
        const zoomLevel = storeLocatorMap.getZoom()
        const center = storeLocatorMap.getCenter()
        const latitude = center.lat().toFixed(4)
        const longitude = center.lng().toFixed(4)

        // Clear overview list if zoomed out
        if (zoomLevel < window.app.zoomLevelMin) {
            removeAllMarkers()
            showZoomOverlay()
            storeOverview.innerHTML = ''
            return
        }

        hideZoomOverlay()

        const bounds = storeLocatorMap.getBounds();
        let mapWidth = 0;
        if (bounds) {
            const northEast = bounds.getNorthEast();
            const northWest = new google.maps.LatLng(northEast.lat(), bounds.getSouthWest().lng());

            mapWidth = Math.round(google.maps.geometry.spherical.computeDistanceBetween(
                northEast,
                northWest
            ));
        }

        // Escape values
        const params = new URLSearchParams()
        params.append('zoom', zoomLevel)
        params.append('distance', mapWidth)
        params.append('lat', latitude)
        params.append('lng', longitude)
        params.append('location', searchStoresInput.value)

        if (window.app.lang) {
            params.append('lang', window.app.lang)
        }

        if (window.app.filter) {
            params.append('filter', window.app.filter)
        }

        if (window.app.exclude) {
            params.append('exclude', '1')
        }

        axios.post('/store-locator/stores', params)
            .then(response => {
                // Clear overview list
                removeAllMarkers()

                storeOverview.innerHTML = response.data.overview

                response.data.markers.forEach(store => {
                    const title = store.title
                    const address = `${store.address}, ${store.postal_code}, ${store.city}`
                    const latitude = parseFloat(store.latitude)
                    const longitude = parseFloat(store.longitude)

                    // Markers
                    const marker = new google.maps.Marker({
                        position: {
                            lat: latitude,
                            lng: longitude
                        },
                        title: title
                    })
                    marker.setMap(storeLocatorMap)

                    // Press marker info window
                    marker.addListener('click', () => {
                        infoPopup.setContent(`
                            <h3 class='text-18 font-700'>${title}</h3>
                            <p class="text-14">${address}</p>
                        `)

                        storeLocatorMap.panTo(marker.getPosition())
                        infoPopup.open(storeLocatorMap, marker)
                    })

                    storeLocatorMarkers.push(marker)
                })
            })
    }

    function showZoomOverlay() {
        const overlayChild = storeLocatorMapElem.querySelector('.gm-style-moc')
        if (!overlayChild || !overlayChild.parentElement) return

        const overlayParent = overlayChild.parentElement
        const currentOverlayElement = overlayParent.querySelector('#zoom-overlay')

        if (!currentOverlayElement) {
            // Create overlay element
            const overlayElement = document.createElement('div')
            overlayElement.classList.add('gm-style-moc')
            overlayElement.id = 'zoom-overlay'
            overlayElement.style.zIndex = '4'
            overlayElement.style.position = 'absolute'
            overlayElement.style.height = '100%'
            overlayElement.style.width = '100%'
            overlayElement.style.padding = '0px'
            overlayElement.style.borderWidth = '0px'
            overlayElement.style.margin = '0px'
            overlayElement.style.left = '0px'
            overlayElement.style.top = '0px'
            overlayElement.style.transitionDuration = '0.3s'
            overlayElement.style.opacity = '1'
            overlayElement.innerHTML = `<p class="gm-style-mot">${window.app.zoomInText}</p>`

            overlayParent.appendChild(overlayElement)
        } else {
            currentOverlayElement.style.opacity = '1'
        }
    }

    function hideZoomOverlay() {
        const overlayChild = storeLocatorMapElem.querySelector('.gm-style-moc')
        if (!overlayChild || !overlayChild.parentElement) return

        const overlayParent = overlayChild.parentElement
        const currentOverlayElement = overlayParent.querySelector('#zoom-overlay')

        if (currentOverlayElement) {
            currentOverlayElement.style.opacity = '0'
        }
    }
}

export default (function () {
    const form = document.getElementById('store-locator-form')
    if (!form) return

    const loader = new Loader({
        apiKey: window.app.googleApiKey,
        version: 'weekly',
    })

    loader.importLibrary('maps').then(initMap)
})
