import {submitForm} from '@vo/js/modules/form'

export const getRecaptchaFormId = (form) => {
    const recaptchaBoxes = document.querySelectorAll('.g-recaptcha')
    const targetBox = form.querySelector('.g-recaptcha')

    for (let i = 0; i < recaptchaBoxes.length; i++) {
        if (recaptchaBoxes[i].isEqualNode(targetBox)) {
            return i
        }
    }
}

export const executeRecaptcha = (form) => {
    if (!window.grecaptcha || window.voRecaptchaForm) {
        return
    }

    window.voRecaptchaForm = form

    const targetFormId = getRecaptchaFormId(form)

    window.grecaptcha.execute(targetFormId)
}

const initRecaptcha = () => {
    if (document.querySelector('.g-recaptcha') && document.querySelector('[data-recaptcha]')) {
        const script = document.querySelector('#recaptcha-script')
        script?.setAttribute('src', script?.getAttribute('data-src'))
    }
}

export default () => {
    window.voFormRecaptchaSuccess = (token) => {
        /**
         * @type {HTMLFormElement|null}
         */
        const form = window.voRecaptchaForm
        window.recaptchaToken = token

        delete window.voRecaptchaForm

        // Add reCAPTCHA verifications fields if reCAPTCHA enabled
        const verifyRecaptcha = form.querySelector('#verify') != null
        if (window.recaptchaToken && verifyRecaptcha) {
            const siteKeyInput = document.createElement('input')
            siteKeyInput.type = 'hidden'
            siteKeyInput.name = 'recaptcha_token'
            siteKeyInput.value = window.recaptchaToken

            form.appendChild(siteKeyInput)
        }

        submitForm(form)
    }

    initRecaptcha()
}
