/**
 * Setup Nitro for user forms
 */
export default function () {
    document.addEventListener('submit', async (e) => {
        if (!e.target.hasAttribute('data-nitro-form')) {
            return
        }

        e.preventDefault()

        const data = new FormData(e.target)

        const url = new URL(e.target.action)

        e.target.inert = true

        const res = await fetch(url, {
            method: e.target.method,
            body: data,
            headers: {
                'Nitro-Request': true,
                'Nitro-Fragment': e.target.dataset.nitroForm,
                'Cache-Control': 'no-cache'
            }
        })

        e.target.innerHTML = await res.text()
        e.target.inert = false
    })
}
