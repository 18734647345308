import breakpoints from '../../../breakpoints.json'
import compare from '@vo/js/modules/compare'

const selector = 'form[data-nitro-fragment]'

export default function () {
    document.querySelectorAll(selector).forEach(form => new Nitro(form))
}

class Nitro {
    config = {
        selector: selector,
        fragmentKey: '_fragment',
        fragmentName: 'default',
        pageKey: 'page',
        blurClasses: ['blur-sm'],
        autoScroll: true,
        autoSubmit: true,
        autoSubmitIgnoreNames: [],
        handleHistory: true,
        inputBreakpoint: ''
    }

    /** @var {HTMLFormElement} */
    form

    fragment

    /** @var {AbortController} */
    controller

    /** @var {number} */
    timeout

    /**
     *
     * @param {HTMLFormElement} form
     */
    constructor (form) {
        this.form = form
        this.config = this.parseConfig(form.dataset)
        this.fragment = this.form.querySelector('[data-nitro-fragment]') ?? this.form
        this.config.fragmentName = this.fragment.dataset.nitroFragment

        this.form.addEventListener('input', e => this.config.autoSubmit && this.handleInput(e))
        this.form.addEventListener('submit', e => this.handleSubmit(e))
        document.addEventListener('click', e => this.handleNavigationClick(e))
        window.addEventListener('popstate', e => this.config.handleHistory && this.handlePopState(e))

        history.replaceState({ html: this.fragment.innerHTML }, null)
        this.form.reset()
    }

    parseConfig(data) {
        return {
            selector: data['configSelector'] ?? this.config.selector,
            fragmentKey: data['configFragmentKey'] ?? this.config.fragmentKey,
            fragmentName: data['configFragmentName'] ?? this.config.fragmentName,
            pageKey: data['configPageKey'] ?? this.config.pageKey,
            blurClasses: data['configBlurClasses'] ?? this.config.blurClasses,
            autoScroll: data['configAutoScroll'] ?? this.config.autoScroll,
            autoSubmit: data['configAutoSubmit'] ?? this.config.autoSubmit,
            autoSubmitIgnoreNames: data['configAutoSubmitIgnoreNames'] ?? this.config.autoSubmitIgnoreNames,
            handleHistory: data['configHandleHistory'] ?? this.config.handleHistory,
            inputBreakpoint: data['configInputBreakpoint'] ?? this.config.inputBreakpoint
        }
    }

    shouldRun() {
        const inputBreakpoint = this.config.inputBreakpoint

        if (inputBreakpoint === '' || !breakpoints.hasOwnProperty(inputBreakpoint)) return true

        return window.matchMedia(`(min-width: ${breakpoints[inputBreakpoint]}px)`).matches
    }

    async handlePopState(e) {
        if (!this.shouldRun()) return

        // If a separate fragment section is being used we reload the page to make sure filters are set correctly
        if (this.form !== this.fragment || !e.state.html) {
            return window.location.reload()
        }

        this.fragment.innerHTML = e.state.html
    }

    async handleNavigationClick(e) {
        if (!this.shouldRun()) return
        if (!e.target.matches('[data-navigation] a, [data-navigation] a *')) return
        if (!this.form.contains(e.target)) return

        const element = e.target.closest('a')

        e.preventDefault()

        if (this.config.autoScroll) {
            this.form.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' })
        }

        await this.navigate(element.href)
    }

    handleInput(e) {
        if (!this.shouldRun()) return
        // Don't submit the form if the input name is in the ignore list
        if (this.config.autoSubmitIgnoreNames.includes(e.target.name)) return

        const debounceTypes = ['date', 'datetime-local', 'email', 'number', 'month', 'password', 'search', 'tel', 'text', 'time', 'url', 'month', 'week']

        // Debounce if we're dealing with text-like input fields, else just submit the form on input
        clearTimeout(this.timeout)

        if (debounceTypes.includes(e.target.type)) {
            this.timeout = setTimeout(() => {
                this.form.requestSubmit()
            }, 600)
        } else {
            this.form.requestSubmit()
        }
    }

    async handleSubmit(e) {
        if (!this.shouldRun()) return

        e.preventDefault()

        // Get the current active input so we can refocus it after the html swap
        const activeId = document.activeElement?.id

        const url = new URL(window.location)

        const params = new URLSearchParams(new FormData(this.form))
        params.delete(this.config.pageKey)
        params.forEach((value, key) => {
            if (value === null || value === '') params.delete(key)
        })

        url.search = params.toString() ? '?' + params.toString() : ''

        await this.navigate(url)

        try {
            // Try to put focus back on the previously active input
            const activeElement = document.getElementById(activeId)
            activeElement?.focus()
            activeElement?.setSelectionRange(-1, -1)
        } catch (e) {
            // It's okay if this fails
        }

        // If the entire form's html gets replaced we reset the form so the input values are correct when navigating back
        if (this.form === this.fragment) {
            this.form.reset()
        }

        // Reset the product comparison form
        compare.reset()
    }

    /**
     *
     * @param {URL|string} url
     * @returns {Promise<void>}
     */
    async navigate(url) {
        this.form.dispatchEvent(new Event('nitro.navigate'))

        // Abort any previous request if it's still going
        if (this.controller) {
            this.controller.abort()
        }

        this.startLoading()

        url = new URL(url)

        this.controller = new AbortController()
        this.fragment.innerHTML = await this.fetchHtml(url, { signal: this.controller.signal })

        this.stopLoading()

        if (this.config.handleHistory) {
            history.pushState({ html: this.fragment.innerHTML }, null, url)
        } else {
            history.replaceState({ html: this.fragment.innerHTML }, null, url)
        }

        this.form.dispatchEvent(new Event('nitro.navigate.finished', { bubbles: true }))
    }

    /**
     *
     * @param {URL} url
     * @param {RequestInit} init
     * @returns {Promise<string>}
     */
    async fetchHtml(url, init = {}) {
        url = new URL(url)

        // Add fragment name to URL for cache busting
        url.searchParams.set(this.config.fragmentKey, this.config.fragmentName)

        const res = await fetch(url, {
            ...init,
            headers: {
                ...(init?.headers ?? {} ),
                'Nitro-Request': true,
                'Nitro-Fragment': this.config.fragmentName,
            }
        })

        return res.text()
    }

    startLoading() {
        this.form.querySelectorAll('[data-blur]').forEach(el => {
            el.classList.add(...this.config.blurClasses)
        })

        this.form.querySelectorAll('input, select, fieldset').forEach(el => {
            el.disabled = true
        })
    }

    stopLoading() {
        this.form.querySelectorAll('[data-blur]').forEach(el => {
            el.classList.remove(...this.config.blurClasses)
        })

        this.form.querySelectorAll('input, select, fieldset').forEach(el => {
            el.disabled = false
        })
    }
}
