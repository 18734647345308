import { ariaCreateExpander, ariaSet, ariaGetControlledElement } from '@vo/js/utils/aria'

/**
 * Toggles dropdown when clicked
 */
const toggleDropdown = () => {
    document.querySelectorAll('[data-module="dropdown"]').forEach(element => {
        const button = element.querySelector('button')
        
        if (!button) return

        ariaCreateExpander(button, {
            mode: 'class',
            onOpen: () => {
                element.classList.remove('z-20')
                element.classList.add('z-30')
            },
            onClose: () => {
                element.classList.add('z-20')
                element.classList.remove('z-30')
            }
        })
    })
}

/**
 * Selects when value when clicked
 */
const selectValue = () => {
    document.querySelectorAll('[data-module="dropdown"]').forEach(element => {
        element.querySelectorAll('[data-value]').forEach(value => {
            value.addEventListener('click', () => {
                // Make all values inactive
                element.querySelectorAll('[data-value]').forEach(value => {
                    value.removeAttribute('data-active')
                })

                // Make clicked item active
                value.setAttribute('data-active', '')

                // In case there is a native select make select option active
                const select = element.querySelector('select')
                const activeValue = value.dataset.value
                if (select) {
                    select.value = activeValue
                    select.dispatchEvent(new Event('change'))
                }

                // Update dropdown label
                const activeLabel = value.textContent
                element.querySelector('[data-label]').textContent = activeLabel

                // Close dropdown
                closeDropdown(element)
            })
        })
    })
}

/**
 * Triggers dropdown to close when clicked outside for UX purposes
 */
const clickOutsideClose = () => {
    const elements = document.querySelectorAll('[data-module="dropdown"]')

    if (!elements) return

    document.addEventListener('click', event => {
        elements.forEach(element => {
            const isClickInside = element.contains(event.target)
            if (!isClickInside) {
                closeDropdown(element)
            }
        })
    })
}

/**
 * Triggers dropdown to close when the ESC key is entered
 */
const escKeyClose = () => {
    const elements = document.querySelectorAll('[data-module="dropdown"]')

    if (!elements) return

    window.onkeydown = event => {
        if (event.keyCode === 27) {
            elements.forEach(element => {
                closeDropdown(element)
            })
        }
    }
}

/**
 * Closes the dropdown
 */
function closeDropdown (element) {
    const button = element.querySelector('button')
    const dropdown = ariaGetControlledElement(button)
    
    ariaSet('expanded', button, 'false')
    
    dropdown.classList.add('hidden')
    element.classList.add('z-20')
    element.classList.remove('z-30')
}

export default (function () {
    toggleDropdown()
    selectValue()
    clickOutsideClose()
    escKeyClose()
})
