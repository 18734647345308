import { ariaCreateExpander } from '../utils/aria'
import Cookies from 'js-cookie'

export default (function () {
    const close = document.querySelector('#close-notification-bar')

    if (!close) return

    const type = close.dataset.type
    let types = Cookies.get('notificationBarClosed') ? JSON.parse(Cookies.get('notificationBarClosed')) : []

    ariaCreateExpander(close, {
        mode: 'class',
        onClose: () => {
            types.push(type)
            Cookies.set('notificationBarClosed', JSON.stringify(types), { expires: 1 })
        }
    })
})
