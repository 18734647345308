import breakpoints from '../../../breakpoints.json'

class ProductCompare {
    static popup =  document.querySelector('[data-compare-popup]')
    static inputs = document.querySelector('[data-compare-form-inputs]')
    static checkboxes = []
    static mq = window.matchMedia(`(min-width: ${breakpoints.lg}px)`)
    static limit = this.mq.matches ? 3 : 2

    static init () {
        if (this.popup) {
            this.update()
            this.addMediaQueryListeners()
        }
    }

    static update () {
        this.getCheckboxes()
        this.emptyForm()
        this.addInputs()
        this.togglePopup()
        this.popupMessage()
        this.disableCheckboxes()
        this.disableSubmitButton()
    }

    static getCheckboxes () {
        this.checkboxes = document.querySelectorAll('[data-compare-checkbox]')

        this.checkboxes.forEach(checkbox => {
            checkbox.addEventListener('change', () => {
                this.update()
            })
        })
    }

    static emptyForm () {
        this.inputs.innerHTML = ''
    }

    static addInputs () {
        this.checkboxes.forEach(checkbox => {
            if (checkbox.checked && this.inputs.childNodes.length !== this.limit) {
                this.inputs.innerHTML += ('<input type="hidden" name="products[]" value="'+ checkbox.value +'">')
            }
        })
    }

    static togglePopup () {
        if (this.inputs.childNodes.length) {
            this.popup.setAttribute('data-active', '')
        } else {
            this.popup.removeAttribute('data-active')
        }
    }

    static popupMessage () {
        if (this.inputs.childNodes.length === this.limit) {
            this.popup.querySelector('[data-compare-message-limit]').classList.add('hidden')
            this.popup.querySelector('[data-compare-message-full]').classList.remove('hidden')
        } else {
            this.popup.querySelector('[data-compare-message-limit]').classList.remove('hidden')
            this.popup.querySelector('[data-compare-message-full]').classList.add('hidden')
        }
    }

    static disableCheckboxes () {
        this.checkboxes.forEach(checkbox => {
            const label = checkbox.closest('label')

            if (this.inputs.childNodes.length === this.limit) {
                if (!checkbox.checked) {
                    checkbox.setAttribute('disabled', '')
                    if (label) {
                        label.setAttribute('disabled', '')
                    }
                }
            } else {
                checkbox.removeAttribute('disabled')
                if (label) {
                    label.removeAttribute('disabled')
                }
            }
        })
    }

    static disableSubmitButton () {
        if (this.inputs.childNodes.length > 1) {
            this.popup.querySelector('[data-compare-submit-button]').removeAttribute('disabled')
        } else {
            this.popup.querySelector('[data-compare-submit-button]').setAttribute('disabled', '')
        }
    }

    static addMediaQueryListeners () {
        this.mq.onchange = (event) => {
            if (event.matches) {
                this.limit = 3
            } else {
                this.limit = 2
            }
            this.update()
            this.popupMessage()
        }
    }

    static reset () {
        this.checkboxes.forEach(checkbox => {
            checkbox.checked = false
        })
        this.update()
    }
}

const compare = (function () {
    const compare = ProductCompare

    return {
        init: () => compare.init(),
        reset: () => compare.reset()
    }
})()

export default compare
