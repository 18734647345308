import { ariaGetControlledElement } from '../utils/aria'

export const toggleTabIndex = (toggle) => {
    const parent = ariaGetControlledElement(toggle)
    const tabIndexElements = parent.querySelectorAll('[tabindex]')
    tabIndexElements.forEach(el => {
        const curIndex = el.tabIndex
        
        if (curIndex === -1) {
            el.tabIndex = 0
        } else {
            el.tabIndex = -1
        }
    })
}
