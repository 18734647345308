import { getVisoUniqueId } from '.'

export default (function () {
    const uniqueId = getVisoUniqueId()
    const openButtons = document.querySelectorAll('.viso-signup-open')
    const signupPopup = document.querySelector('#viso-signup')
    const closeButton = document.querySelector('#viso-signup-close')

    if (!uniqueId || !openButtons.length || !signupPopup || !closeButton) {
        // Initially we only loaded the form when a unique ID was present, but now we switched to cookies we cannot
        // check that in PHP anymore. So we check it here and remove the form if it's not needed.
        if (signupPopup) {
            signupPopup.remove()
        }

        return
    }

    const open = () => {
        document.body.classList.add('overflow-hidden')
        signupPopup.classList.remove('hidden')

        // Dynamically set the hidden field value, instead of via PHP.
        const uniqueIdField = document.querySelector('#viso-unique-id')
        if (uniqueIdField) {
            uniqueIdField.value = uniqueId
        }
    }

    const close = () => {
        document.body.classList.remove('overflow-hidden')
        signupPopup.classList.add('hidden')
    }

    openButtons.forEach(button => {
        button.addEventListener('click', () => {
            open()
        })
    })

    closeButton.addEventListener('click', () => close())

    document.addEventListener('click', (event) => {
        if (!event.target.closest('#viso-signup-inner') && !event.target.closest('.viso-signup-open')) {
            close()
        }
    })
})
