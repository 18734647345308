import { ariaCreateExpander } from '@vo/js/utils/aria'

const toggleSaleLocations = () => {
    const toggle = document.querySelector('#toggleSaleLocations')

    if (!toggle) return
    
    ariaCreateExpander(toggle, {
        mode: 'slide'
    })
}

export default (function () {
    toggleSaleLocations()
})
