import breakpoints from '../../../breakpoints.json'
import { ariaCreateExpander, ariaToggle, ariaGetControlledElement } from '../utils/aria'

class listingFilters {
    static toggle = document.querySelector('#toggleListingFiltersPanel')
    static panel = document.querySelector('#listingFiltersPanel')
    static popup = ariaGetControlledElement(this.toggle)
    static closeButton = document.querySelector('#closeListingFiltersPanel')
    static mq = window.matchMedia(`(min-width: ${breakpoints.lg}px)`)
    static form = document.querySelector('#listingFiltersForm')
    static selects = this.form?.querySelectorAll('select') || []

    static init() {
        if (!this.toggle || !this.popup) return

        this.togglePopup()
        this.addEventListeners()
    }

    static addEventListeners() {
        this.clickCloseButton()
        this.clickOutsidePopup()
        this.escKey()
    }

    static togglePopup() {
        ariaCreateExpander(this.toggle, {
            mode: 'attr',
            onToggle: () => {
                document.body.classList.toggle('overflow-hidden')
            }
        })
    }

    static closePopup() {
        ariaToggle('expanded', this.toggle, 'true')
        this.popup.setAttribute('hidden', '')
        document.body.classList.remove('overflow-hidden')
    }

    static clickOutsidePopup() {
        document.addEventListener('click', event => {
            const isClickInside = this.panel.contains(event.target) || this.toggle.contains(event.target)

            if (!isClickInside && this.popupIsOpen()) {
                this.closePopup()
            }
        })
    }

    static escKey() {
        window.onkeydown = event => {
            if (event.keyCode === 27 && this.popupIsOpen()) {
                this.closePopup()
            }
        }
    }

    static popupIsOpen() {
        return !this.popup.hasAttribute('hidden')
    }

    static clickCloseButton() {
        if (!this.closeButton) return

        this.closeButton.addEventListener('click', () => {
            this.closePopup()
        })
    }
}

export default (function () {
    listingFilters.init()
})
