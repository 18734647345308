class Video {
    static videos = document.querySelectorAll('[data-module="video"]')
    static timestamps = document.querySelectorAll('[data-video-timestamp]')

    static init () {
        if (!this.videos) return

        this.addEventListeners()
    }

    static addEventListeners () {
        this.playButton()
        this.playFromTimestamp()
    }

    static playButton () {
        this.videos.forEach(video => {
            const playButton = video.querySelector('[data-video-play-button]')

            if (!playButton) return

            playButton.addEventListener('click', () => {
                this.addIframe(video)
            })
        })
    }

    static playFromTimestamp () {
        if (!this.timestamps) return

        this.timestamps.forEach(timestamp => {
            timestamp.addEventListener('click', () => {
                const video = document.querySelector(`${timestamp.getAttribute('href')} [data-module="video"]`)
                const time = this.convertTimeToSeconds(timestamp.dataset.videoTimestamp)
                this.addIframe(video, 1, time)
            })
        })
    }

    static addIframe (container, autoplay = 1, timestamp = 0) {
        if (!container) return

        const src = container.dataset.videoSrc

        if (!src) return
        
        container.innerHTML = `<iframe class="absolute top-0 left-0 h-full w-full" width="100%" height="auto" src="${src}?autoplay=${autoplay}&start=${timestamp}" allow="autoplay" frameborder="0" allowfullscreen></iframe>`
    }

    static convertTimeToSeconds (time) {
        if (!time) return

        time = time.split(':')
        let seconds = parseInt(time[2])

        // Add hours to seconds
        seconds = seconds + (parseInt(time[0]) * 3600)
        
        // Add minutes to seconds
        seconds = seconds + (parseInt(time[1]) * 60)

        return seconds
    }
}

export default (function () {
    Video.init()
})
