import Cookies from 'js-cookie'

export const getVisoUniqueId = () => {
    let uniqueId = Cookies.get('visoUniqueId') ?? null

    // Fix for previous version where it could be set to 'false'.
    if (uniqueId === 'false') {
        uniqueId = null
        Cookies.remove('visoUniqueId')
    }

    const url = new URL(window.location.href)

    // In case the URL parameter is present, we use that.
    if (url.searchParams.has('unique_id')) {
        const uniqueIdParam = encodeURIComponent(url.searchParams.get('unique_id'))

        if (uniqueIdParam) {
            uniqueId = uniqueIdParam
            Cookies.set('visoUniqueId', uniqueIdParam, { expires: 1 })
        }
    }

    return uniqueId
}

export const enableVisoButtons = () => {
    const onVisoPage = window.location.href.includes('viso')

    if (!onVisoPage) {
        return
    }

    const uniqueId = getVisoUniqueId()

    if (uniqueId) {
        const joinButtons = document.querySelectorAll('button[data-join-viso-button]')
        joinButtons.forEach(button => {
            button.setAttribute('aria-disabled', 'false')
            button.classList.remove('disabled')
        })
    }
}
