export default (function () {
    const form = document.getElementById('blogFiltersForm')
    
    if (!form) return

    const formElements = form.querySelectorAll('input, select')
    const topicButtons = form.querySelectorAll('[data-topic-button]')
    const topicSelect = form.querySelector('select[name="topic"]')

    formElements.forEach(element => {
        element.addEventListener('change', () => {
            form.submit()
        })
    })

    topicButtons.forEach(topic => {
        topic.addEventListener('click', () => {
            const value = topic.dataset.value
            topicSelect.value = value
            form.submit()
        })
    })
})
