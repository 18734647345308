const scrollToFormMessage = () => {
    const element = document.getElementById('formMessage')
    
    if (!element) return

    setScrollMargin(element)

    element.scrollIntoView({
        behavior: 'smooth'
    })
}

const scrollToAnchor = () => {
    const scrollToLinks = document.querySelectorAll('[data-scrollto]')
    
    if (!scrollToLinks) return

    scrollToLinks.forEach((link) => {
        link.addEventListener('click', (e) => {
            e.preventDefault()
            const targetId = link.getAttribute('href')
            const target = document.querySelector(targetId)
            
            if (target) {
                setScrollMargin(target)
                
                target.scrollIntoView({ behavior: 'smooth' })
            }
        })
    })
}

const setScrollMargin = (target) => {
    if (!target) return

    const offset = document.querySelector('#site-header').offsetHeight + 40
    target.style.scrollMarginTop = offset + 'px'
}

export default (function () {
    scrollToFormMessage()
    scrollToAnchor()
})
