import { ariaGetControlledElement, ariaSet } from '../utils/aria'

/**
 * Base tab functionality
 */
const tabList = () => {
    document.querySelectorAll('[data-module="tabs"]').forEach((section) => {

        section.querySelectorAll('[data-tab]').forEach((tab) => {
            tab.addEventListener('click', () => {
                // Disable all tabs
                section.querySelectorAll('[data-tab]').forEach((tab) => {
                    ariaSet('selected', tab, 'false')
                    ariaSet('hidden', ariaGetControlledElement(tab), 'true')
                })

                // Enable clicked tab
                ariaSet('selected', tab, 'true')
                ariaSet('hidden', ariaGetControlledElement(tab), 'false')

                updatedRelatedSelect(tab)
            })
        })
    })
}

/**
 * Updates the tabs in case a custom dropdown within the module changes
 */
const relatedSelect = () => {
    document.querySelectorAll('[data-module="tabs"]').forEach((section) => {
        const select = section.querySelector('select')

        if (!select) return
        
        select.addEventListener('change', () => {
            const selectedTabButton = document.querySelector(`[aria-controls="${select.value}"]`)
            const selectedTabPanel = document.querySelector(`#${select.value}`)

            if (!selectedTabButton || !selectedTabPanel) return

            // Disable all tabs
            section.querySelectorAll('[data-tab]').forEach((tab) => {
                ariaSet('selected', tab, 'false')
                ariaSet('hidden', ariaGetControlledElement(tab), 'true')
            })

            // Enable selected tab
            ariaSet('selected', selectedTabButton, 'true')
            ariaSet('hidden', selectedTabPanel, 'false')
        })
    })
}

/**
 * Updates the custom dropdown in case it's included within the module
 */
function updatedRelatedSelect (tab) {
    if (!tab) return

    const section = tab.closest('[data-module="tabs"]')
    const select = section.querySelector('select')
    const label = section.querySelector('[data-label]')
    const options = section.querySelectorAll('[data-option]')
    const value = tab.getAttribute('aria-controls')

    if (select && value && options) {
        // Update native select value
        select.value = value

        // Update custom dropdown active state
        options.forEach(option => {
            if (option.dataset.value === value) {
                option.setAttribute('data-active', '')

                // Update custom dropdown label
                label.textContent = option.textContent
            } else {
                option.removeAttribute('data-active')
            }
        })
    }
}

export default (function () {
    tabList()
    relatedSelect()
})
