/**
 * Translate the given key.
 * @param {String} key
 * @param {Object} replace
 * @returns {String}
 */
export const __ = (key, replace = {}) => {
    let translation = window.i18n?.translations?.[key] || key

    if (Object.keys(replace).length) {
        for (const [key, value] of Object.entries(replace)) {
            translation = translation.replaceAll(`:${key}`, value)
        }
    }

    return translation
}
